// Frameworks
import React from 'react';

// Material UI
import Typography from '@mui/material/Typography';

// App Components
import SEO from '../components/seo';

// Central Logging
import { Logger } from '../utils/logger';
const log = Logger('[Route] NotFound');
log.debug('initialized');

// NotFound Route
const NotFound = () => {
  return (
    <main>
      <SEO title={'Page Not Found'} />

      <Typography variant="h2" color="secondary">404 - Not Found!</Typography>
    </main>
  );
};

export default NotFound;
